<h1>
  User node hierarchy

  <button (click)="newChildClicked(null)" class="btn btn-outline-secondary mt-2 float-right">
    <i class="fa fa-plus"></i> Add root node
  </button>

  <a routerLink="sams" class="btn btn-outline-primary mt-2 mr-1 float-right">
    <i class="fa fa-plus"></i> Register SAMS hive
  </a>
</h1>

<div class="row">
  <div class="col-8">
    <ng-template #recursiveCard let-list>
      <div *ngFor="let node of list" class="card" [class.border-secondary]="node.id === currentNode?.id">
        <div class="card-header" (click)="editClicked(node)">
          <app-node-label [node]="node" [showLocation]="true"></app-node-label>
          <div class="buttons float-right">
            <button class="btn btn-sm btn-outline-info mr-1" (click)="openReport(node.id)">
              <i class="fa fa-search"></i> Reports
            </button>
            <a class="btn btn-sm btn-outline-primary mr-1" [routerLink]="node.id">
              <i class="fa fa-search"></i> Details
            </a>
            <button class="btn btn-sm btn-outline-success" (click)="newChildClicked(node); $event.stopPropagation();">
              <i class="fa fa-plus"></i> Add child
            </button>
          </div>
        </div>

        <div *ngIf="node.children?.length > 0" class="card-body">
          <ng-container *ngTemplateOutlet="recursiveCard; context:{ $implicit: node.children }"></ng-container>
        </div>

      </div>
    </ng-template>

    <div class="card" *ngIf="nodes.length === 0">
      <div class="card-body">
        No nodes defined, yet.
      </div>
    </div>

    <ng-container *ngTemplateOutlet="recursiveCard; context:{ $implicit: nodes }"> </ng-container>
  </div>

  <div class="col-4">
    <div class="sidebar">

      <div class="card" *ngIf="currentNode">
        <div class="card-header">
          Node details
        </div>
        <div class="card-body">
          <div class="form-group">
            <label for="nodeId">ID</label>
            <input type="text" readonly class="form-control" name="nodeId" [value]="currentNode.id || 'New node'" />
          </div>

          <div class="form-group">
            <label for="nodeName">Name</label>
            <input type="text" class="form-control" name="nodeName" [(ngModel)]="currentNode.name" />
          </div>

          <div class="form-group">
            <label for="nodeType">Type</label>
            <select class="form-control" name="nodeType" [(ngModel)]="currentNode.type">
              <option [ngValue]="type.value" *ngFor="let type of nodeTypes">{{type.title}}</option>
            </select>
          </div>
          <a class="btn btn-outline-primary mr-1" [routerLink]="currentNode.id">
            <i class="fa fa-search"></i> Details and mapping
          </a>
          <button class="btn btn-outline-info" (click)="openReportList(currentNode.id)">
            <i class="fa fa-search"></i> Reports
          </button>

        </div>
        <div class="card-footer">
          <button class="btn btn-outline-success mr-2" (click)="saveClicked()"><i class="fa fa-check"></i> Save</button>
          <button class="btn btn-outline-secondary mr-2" (click)="cancelClicked()"><i class="fa fa-times"></i> Cancel</button>
          <button *ngIf="currentNode.id" class="btn btn-outline-danger float-right" (click)="deleteClicked()"><i
            class="fa fa-trash"></i>
            Delete
          </button>
        </div>
      </div>

    </div>
  </div>
</div>

<ng-template #confirmDelete let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h5 class="modal-title">Confirm delete</h5>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Node deletion is <strong>not</strong> reversible. This operation will delete:</p>
    <ul>
      <li>metadata about <strong>this</strong> node and its <strong>children</strong> nodes,</li>
      <li>device <strong>client IDs</strong> and source <strong>mappings</strong>,</li>
      <li>already <strong>loaded data</strong> (reports) will not be accessible any more.</li>
    </ul>
    <p>
      Are you sure want to delete node {{currentNode.name}}?
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)=c()>Yes, delete it anyways!</button>
    <button type="button" class="btn btn-outline-secondary" (click)=d() ngbAutofocus>No, keep it for now.</button>
  </div>
</ng-template>
