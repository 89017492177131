<div class="row">
  <div class="col">
    <nav>
      <ul class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/configs">All configs</a></li>
      </ul>
    </nav>
  </div>
</div>


<form>
  <div class="row">
    <div class="col" [formGroup]="configForm">
      <div class="form-group row">
        <label for="configId" class="col-sm-2 col-form-label">ID</label>
        <div class="col-sm-4">
          <input type="text" readonly class="form-control" name="configId" formControlName="id"
                 placeholder="Generated configuration ID"/>
        </div>
      </div>

      <div class="form-group row">
        <label for="configName" class="col-sm-2 col-form-label">Name</label>
        <div class="col-sm-10">
          <input type="text" class="form-control" name="configName" formControlName="name" required
                 placeholder="Enter configuration name"/>
        </div>
      </div>
      <div class="form-group row">
        <label for="configName" class="col-sm-2 col-form-label">Config</label>
        <div class="col-sm-10">
          <div class="code-editor form-control" #codeEditor></div>
        </div>
      </div>
    </div>
  </div>
</form>

<button type="submit" class="btn btn-outline-primary mr-2" (click)="saveClicked()" [disabled]="configForm.invalid">
  <i class="fa fa-check"></i> Save
</button>
<button type="button" class="btn btn-outline-secondary mr-2" (click)="cancelClicked()">
  <i class="fa fa-times"></i> Cancel
</button>

<button *ngIf="configForm.get('id').value" type="button" class="btn btn-outline-danger float-right"
        (click)="deleteClicked()">
  <i class="fa fa-trash"></i> Delete
</button>
